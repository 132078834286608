import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

export default function SnackbarAlert(props) {
  const { open, severity, notificationMessage, setOpen } = props;
  const state = {
    open: false,
    vertical: 'bottom',
    horizontal: 'left',
  };

  const { vertical, horizontal } = state;
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <Stack spacing={2} sx={{ width: 310 }}>
      <Snackbar open={open} autoHideDuration={5000} onClose={handleClose} key={vertical + horizontal} anchorOrigin={{ vertical, horizontal }}>
        <Alert onClose={handleClose} severity={severity} sx={{ width: 500 }} variant='filled'>
          {notificationMessage}
        </Alert>
      </Snackbar>
    </Stack>
  )
}
