import { useState } from "react";
import { Box, Paper, TextField, Typography, Grid } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { signInWithEmail, resetPassword } from "../utils/firebase";

const Login = ({
  setOpenSnackBar,
  setSeveritySnackBar,
  setMessageSnackBar,
}) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = async (event) => {
    setOpenSnackBar(false);
    event.preventDefault();
    
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    if (!emailPattern.test(email)) {
      setOpenSnackBar(true);
      setSeveritySnackBar("error");
      setMessageSnackBar("El correo electrónico ingresado no es válido.");
      return;
    }

    setLoading(true);
    const result = await signInWithEmail(email, password);
    if (!result) {
      setSeveritySnackBar("error");
      setMessageSnackBar(
        "Error logging in, please check your credentials and try again"
      );
      setOpenSnackBar(true);
      setLoading(false);
    }
  };

  const passwordResetHandler = () => {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    if (!emailPattern.test(email)) {
      setOpenSnackBar(true);
      setSeveritySnackBar("error");
      setMessageSnackBar("El correo electrónico ingresado no es válido.");
      return;
    }

    resetPassword(email)
      .then(() => {
        setOpenSnackBar(true);
        setSeveritySnackBar("success");
        setMessageSnackBar(
          "Tu solicitud de restablecimiento de contraseña fue recibida, revisa tu correo " +
            email
        );
      })
      .catch((error) => {
        setOpenSnackBar(true);
        setSeveritySnackBar("error");
        setMessageSnackBar(
          "Error al solicitar restablecimiento de contraseña para el correo " +
            email
        );
        console.error("Error inesperado:", error);
      });
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        background: "#000000",
        width: "100%",
        flexDirection: "column",
      }}
    >
      <Grid container>
        <Grid item xs={2} sm={2} md={4}></Grid>
        <Grid
          item
          xs={8}
          sm={8}
          md={4}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "8px",
            alignItems: "center",
          }}
        >
          <img
            src="https://storage.googleapis.com/club-liquidos/logo_landing.svg"
            style={{
              width: "80%",
            }}
            alt="liquidos.cl"
          />
          <Paper
            elevation={3}
            sx={{
              p: 2,
              mt: 3,
              width: "100%",
              boxSizing: "border-box",
            }}
          >
            <Typography variant="h4" sx={{ mt: 1 }}>
              LOGIN
            </Typography>
            <Box component="form" onSubmit={handleSubmit}>
              <TextField
                type="email"
                label="EMAIL"
                value={email}
                onChange={handleEmailChange}
                fullWidth
                margin="normal"
                size="small"
              />
              <TextField
                type="password"
                label="CONTRASEÑA"
                value={password}
                onChange={handlePasswordChange}
                fullWidth
                margin="normal"
                size="small"
              />
              <LoadingButton
                type="submit"
                variant="contained"
                sx={{
                  mt: 2,
                  background: "#FFDF00",
                  color: "#000000",
                  fontWeight: 700,
                  fontSize: "20px",
                }}
                loading={loading}
                size="small"
              >
                Entrar
              </LoadingButton>
            </Box>
          </Paper>
          <Typography
            variant="body"
            sx={{ fontStyle: "italic", color: "#FFDF00" }}
          >
            POWERED BY A.I.R.E CLUB LIQUIDOS.CL
          </Typography>
          <Typography
            variant="body"
            sx={{
              fontStyle: "italic",
              color: "#FFDF00",
              textDecoration: "underline", // Agregar subrayado
              cursor: "pointer", // Cambiar el cursor a una mano al posicionarlo
            }}
            onClick={passwordResetHandler}
          >
            ¿Olvidaste tu contraseña? Clic aquí para restablecer contraseña
          </Typography>
        </Grid>
        <Grid item xs={2} sm={2} md={4}></Grid>
      </Grid>
    </Box>
  );
};

export default Login;