/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Box, Typography, LinearProgress } from '@mui/material'

const Elasticidad = ({
  user,
  setSelectedIndex,
  dashboards,
}) => {
  const [src, setSrc] = useState(null)
  const [userExist, setUserExist] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    let position = 0
    dashboards?.forEach((element, i) => {
      if(element.endpoint==="elasticidad") {
        position = i
      }
    });
    setSelectedIndex(position);
    if (user) {
      setUserExist(true)
      setSrc(`/streamlit${user.number}/?page=elasticidad&embed=true&token=${user.accessToken}`)
    }
  }, [dashboards, setSelectedIndex]);

  const handleLoad = () => {
    setIsLoading(false)
  }

  return (
    <Box sx={{ width: '100%', mt: 0, height: '100%' }}>
            <Typography
                variant={isLoading ? "body2" : "h4"}
                sx={isLoading? { mb: 2, pt: 5} : { mb: 2 }}>
                {isLoading ? "Loading..." : null}
            </Typography>
            {isLoading && <LinearProgress />}
            {userExist ? <iframe
                title='Elasticidad de Precios'
                src={src}
                style={{ width: '100%', height: '85vh', border: 'none' }}
                onLoad={handleLoad}
                allow="clipboard-write"
            /> : null}
        </Box>
  );
};

export default Elasticidad;
