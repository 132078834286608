import { useEffect, useState } from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';
import LoadingButton from '@mui/lab/LoadingButton';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

const UsersModal = ({
    user,
    config,
    organization,
    users,
    openModal,
    handleClose,
    type,
    position,
    setReloadUsers,
    setOpenSnackBar,
    setSeveritySnackBar,
    setMessageSnackBar,
}) => {
    const [loading, setLoading] = useState(false);
    const [formRole, setFormRole] = useState('user')
    const [formBrand, setFormBrand] = useState("todas")
    const [formStatus, setFormStatus] = useState(true)

    console.log(users, position)

    useEffect(() => {
        if (users[position]?.active !== undefined) {
            setFormStatus(users[position].active);
        } else {
            setFormStatus(true)
        }

        if (users[position]?.role !== undefined) {
            setFormRole(users[position].role);
        } else {
            setFormRole("user")
        }

        if (users[position]?.brand !== undefined) {
            setFormBrand(users[position].brand);
        } else {
            setFormBrand("todas")
        }
    }, [users, position]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)
        if (type !== "delete") {
            const formEl = e.target.closest('form');
            const formData = new FormData(formEl);
            const entries = {};
            for (let [key, value] of formData.entries()) entries[key] = value.trim();
            entries.role = formRole
            entries.organizationId = user.organizationId
            if (formRole !== "admin") {
                if (formBrand !== "todas") entries.brand = formBrand
            }
            entries.active = formStatus

            if (type === "add") {
                const { confirmPassword, ...data } = entries
                console.log(data)
                try {
                    const response = await axios.post(`/api/users`, data, config)
                    if (response.data.response.status_response === "NOK") {
                        setLoading(false)
                        setOpenSnackBar(true)
                        setSeveritySnackBar("error")
                        setMessageSnackBar("Error creating user, please try again")
                    } else {
                        setReloadUsers(prev => {
                            return prev + 1
                        })
                        setLoading(false)
                        closeModal()
                    }
                } catch (err) {
                    console.log(err)
                    setLoading(false)
                    setOpenSnackBar(true)
                    setSeveritySnackBar("error")
                    setMessageSnackBar("Error creating user, please try again")
                }
            } else {
                const { email, ...data } = entries
                try {
                    const response = await axios.put(`/api/users/${users[position].uid}`, data, config)
                    if (response.data.response.status_response === "NOK") {
                        setLoading(false)
                        setOpenSnackBar(true)
                        setSeveritySnackBar("error")
                        setMessageSnackBar("Error updating user, please try again")
                    } else {
                        setReloadUsers(prev => {
                            return prev + 1
                        })
                        setLoading(false)
                        closeModal()
                    }
                } catch (err) {
                    console.log(err)
                    setLoading(false)
                    setOpenSnackBar(true)
                    setSeveritySnackBar("error")
                    setMessageSnackBar("Error updating user, please try again")
                }
            }
        } else {
            try {
                const response = await axios.delete(`/api/users/${users[position].uid}`, config)
                if (response.data.response.status_response === "NOK") {
                    setLoading(false)
                    setOpenSnackBar(true)
                    setSeveritySnackBar("error")
                    setMessageSnackBar("Error deleting user, please try again")
                } else {
                    setReloadUsers(prev => {
                        return prev + 1
                    })
                    setLoading(false)
                    closeModal()
                }
            } catch (err) {
                console.log(err)
                setLoading(false)
                setOpenSnackBar(true)
                setSeveritySnackBar("error")
                setMessageSnackBar("Error deleting user, please try again")
            }
        }
    }

    const closeModal = () => {
        handleClose();
    };

    return (
        <Modal
            disableScrollLock={true}
            open={openModal}
            onClose={closeModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 500,
                bgcolor: "background.paper",
                boxShadow: 24,
                pt: 4,
                pb: 4,
                pl: 2,
                pr: 2,
                paddingBottom: 2,
                maxHeight: 720,
                overflowY: "auto",
            }}>
                <Container maxWidth='xl'>
                    {type !== "delete" ?
                        <Grid
                            component="form"
                            onSubmit={handleSubmit}
                            container
                            spacing={2}
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-between"
                            }}
                        >
                            <Grid item xs={12}>
                                <Typography variant="h5" component="h5">{type === "add" ? "New User" : "Edit User"}</Typography>
                            </Grid>
                            <Grid item xs={12} sx={{ width: "100%" }}>
                                <FormControl
                                    required={true}
                                    sx={{
                                        marginTop: 2,
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                    }}
                                >
                                    <InputLabel htmlFor="name">Name</InputLabel>
                                    <Input
                                        id="name"
                                        name="name"
                                        defaultValue={users[position]?.name}
                                        sx={{ width: "100%" }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sx={{ width: '100%' }}>
                                <FormControl
                                    required={true}
                                    sx={{
                                        marginTop: 2,
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                    }}
                                >
                                    <InputLabel htmlFor="email">Email</InputLabel>
                                    <Input
                                        disabled={type === "add" ? false : true}
                                        id="email"
                                        name="email"
                                        type="email"
                                        defaultValue={users[position]?.email}
                                        sx={{ width: "100%" }} />
                                </FormControl>
                            </Grid>
                            <Grid
                                item
                                xs={6}
                                sx={{
                                    marginTop: 2,
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "left"
                                }}
                            >
                                <Typography variant="body1" component="h6">
                                    Status
                                </Typography>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            disabled={type === "add" ? true : false}
                                            checked={formStatus}
                                            onChange={(e) => setFormStatus(e.target.checked)}
                                            inputProps={{ "aria-label": "controlled" }}
                                        />
                                    }
                                    label="Active"
                                />
                            </Grid>
                            <Grid item xs={6} sx={{ width: '100%', mt: 3 }}>
                                <FormControl fullWidth>
                                    <InputLabel id="role">Role</InputLabel>
                                    <Select
                                        id="role"
                                        value={formRole}
                                        label="role"
                                        onChange={(e) => { setFormRole(e.target.value) }}
                                    >
                                        <MenuItem value={"user"}>User</MenuItem>
                                        <MenuItem value={"admin"}>Admin</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sx={{ width: '100%', mt: 3 }}>
                                <FormControl fullWidth>
                                    <InputLabel id="brand">Brand</InputLabel>
                                    <Select
                                        disabled={formRole === "user" ? false : true}
                                        id="brand"
                                        value={formBrand || "todas"}
                                        label="brand"
                                        onChange={(e) => { setFormBrand(e.target.value) }}
                                    >
                                        <MenuItem value={"todas"}>Todas</MenuItem>
                                        {organization?.brands?.length > 0 && organization.brands.map((obj, i) => (
                                            <MenuItem key={"formBrandItem" + i} value={obj}>{obj}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            {type === "add" ? <Grid item xs={12} sx={{ width: '100%' }}>
                                <FormControl
                                    required={true}
                                    sx={{
                                        marginTop: 2,
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                    }}
                                >
                                    <InputLabel htmlFor="password">Password</InputLabel>
                                    <Input
                                        id="password"
                                        name="password"
                                        type="password"
                                        sx={{ width: "100%" }} />
                                </FormControl>
                            </Grid> : null}
                            {type === "add" ? <Grid item xs={12} sx={{ width: '100%' }}>
                                <FormControl
                                    required={true}
                                    sx={{
                                        marginTop: 2,
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                    }}
                                >
                                    <InputLabel htmlFor="confirmPassword">Confirm Password</InputLabel>
                                    <Input
                                        id="confirmPassword"
                                        name="confirmPassword"
                                        type="password"
                                        sx={{ width: "100%" }} />
                                </FormControl>
                            </Grid> : null}
                            <Grid item xs={12} sx={{ mt: 4, mb: 4 }}>
                                <LoadingButton
                                    fullWidth
                                    size="large"
                                    loading={loading}
                                    loadingIndicator="Saving..."
                                    variant="contained"
                                    type="submit"
                                >
                                    {type === "add" ? "Create" : "Update"}
                                </LoadingButton>
                            </Grid>
                        </Grid> :
                        <Grid container columnSpacing={2} rowSpacing={4}>
                            <Grid item xs={12}>
                                <Typography variant="h5" component="h5">Are you sure you want to delete user {users[position]?.name}?</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Button
                                    variant="contained"
                                    sx={{ alignSelf: "flex-end", width: '75%' }}
                                    onClick={closeModal}>No</Button>
                            </Grid>
                            <Grid item xs={6} sx={{ display: "flex", justifyContent: "flex-end" }}>
                                <LoadingButton
                                    variant="contained"
                                    sx={[
                                        {
                                            width: '75%'
                                        },
                                        (theme) => ({
                                            '&:hover': {
                                                backgroundColor: '#b42f2f',
                                            },
                                        })]}
                                    color="error"
                                    loading={loading}
                                    loadingIndicator="Deleting..."
                                    onClick={handleSubmit}>Yes</LoadingButton>
                            </Grid>
                        </Grid>
                    }
                </Container>
            </Box>
        </Modal >
    );
}

export default UsersModal;