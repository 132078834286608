import { useEffect, useState } from 'react';
import axios from 'axios'
import { Box, Typography, Fab } from '@mui/material';
import UsersDataGrid from './UsersDataGrid';
import AddIcon from '@mui/icons-material/Add';

import UsersModal from './UsersModal'
import UsersModalSuperAdmin from './UsersModalSuperAdmin';

function formatDateTime(timestamp) {
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    const hours = ('0' + date.getHours()).slice(-2);
    const minutes = ('0' + date.getMinutes()).slice(-2);
    const seconds = ('0' + date.getSeconds()).slice(-2);

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

const UsersLayout = ({
    user,
    organization,
    setSelectedIndex,
    config,
    setOpenSnackBar,
    setSeveritySnackBar,
    setMessageSnackBar,
    dashboards
}) => {
    const [loading, setLoading] = useState(false)
    const [users, setUsers] = useState([])
    const [openModal, setOpenModal] = useState(false);
    const [modalType, setModalType] = useState("add");
    const [selectedPosition, setSelectedPosition] = useState(0);
    const [reloadUsers, setReloadUsers] = useState(0)

    useEffect(() => {
        setSelectedIndex(dashboards?.length)
        const getUsers = async () => {
            setLoading(true)
            try {
                const response = await axios.get('/api/users', config)
                let usersResponse = response.data.response.data.map((obj, i) => {
                    obj.id = obj.uid
                    obj.actions = i
                    obj.created = formatDateTime(obj.created)
                    obj.updated = formatDateTime(obj.updated)

                    return obj
                })
                console.log(usersResponse)
                setUsers(usersResponse)
                setLoading(false)
            } catch (error) {
                console.log(error)
                setLoading(false)
            }
        }
        getUsers()
    }, [dashboards, reloadUsers, config, setSelectedIndex]);

    const handleOpen = (action, position) => {
        setModalType(action)
        setSelectedPosition(position)
        setOpenModal(true)
    }

    const handleClose = () => setOpenModal(false)

    return (
        <Box
            sx={{
                mt: 6,
                width: '100%'
            }}>
            {user.role === "super-admin" ? openModal && <UsersModalSuperAdmin
                user={user}
                organization={organization}
                openModal={openModal}
                handleClose={handleClose}
                position={selectedPosition}
                type={modalType}
                users={users}
                config={config}
                setReloadUsers={setReloadUsers}
                setOpenSnackBar={setOpenSnackBar}
                setSeveritySnackBar={setSeveritySnackBar}
                setMessageSnackBar={setMessageSnackBar}
            /> : openModal && <UsersModal
                user={user}
                organization={organization}
                openModal={openModal}
                handleClose={handleClose}
                position={selectedPosition}
                type={modalType}
                users={users}
                config={config}
                setReloadUsers={setReloadUsers}
                setOpenSnackBar={setOpenSnackBar}
                setSeveritySnackBar={setSeveritySnackBar}
                setMessageSnackBar={setMessageSnackBar}
            />
            }
            <Typography variant="h4"> Lista de usuarios </Typography>
            <UsersDataGrid
                users={users}
                loading={loading}
                handleOpen={handleOpen}
            />
            <Fab
                onClick={() => handleOpen('add')}
                color="warning"
                aria-label="add"
                sx={{
                    position: 'fixed',
                    bottom: 32,
                    right: 32
                }}
            >
                <AddIcon />
            </Fab>
        </Box>
    );
}

export default UsersLayout;
