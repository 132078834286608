import 'normalize.css';
import './App.css'
import Login from './components/Login'
import { onAuthStateChanged } from "firebase/auth"
import { auth, signUserOut } from './utils/firebase'
import { useEffect, useState } from "react"
import Container from "@mui/material/Container"
import Main2 from './components/Main2'
import axios from 'axios'
import SnackbarAlert from './components/utils/SnackbarAlert'

import { createTheme, ThemeProvider } from '@material-ui/core/styles';

const theme = createTheme({
  typography: {
    fontFamily: 'Bebas Neue, Arial',
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [
          {
            fontFamily: 'Bebas Neue',
            fontStyle: 'normal',
            fontWeight: 400,
            src: `
              local('Bebas Neue'),
              local('BebasNeue-Regular'),
              url(https://fonts.gstatic.com/s/bebasneue/v2/JTUSjIg69CK48gW7PXoo9Wdhzg.ttf) format('truetype')
            `,
          },
        ],
      },
    },
  },
});

function App() {
  const [user, setUser] = useState(null)
  const [organization, setOrganization] = useState(null)
  const [loaded, setLoaded] = useState(false)
  const [openSnackBar, setOpenSnackBar] = useState(false)
  const [severitySnackBar, setSeveritySnackBar] = useState("info")
  const [messageSnackBar, setMessageSnackBar] = useState("")

  const getUser = async (userId, token) => {
    try {
      const response = await axios.get(`/api/users/${userId}`, {
        headers: {
          Authorization: token,
        }
      })
      return response.data.response.data
    } catch (error) {
      console.log(error)
      signUserOut()
      return []
    }
  }

  const getOrganization = async (token) => {
    try {
      const response = await axios.get(`/api/organizations`, {
        headers: {
          Authorization: token,
        }
      })
      return response.data.response.data
    } catch (error) {
      console.log(error)
      return []
    }
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (firebaseUser) => {
      if (firebaseUser) {
        const refreshToken = () => {
          console.log("GET TOKEN")
          firebaseUser.getIdToken(true)
            .then((idToken) => {
              getUser(firebaseUser.uid, idToken).then(userResult => {
                getOrganization(idToken).then(organizationResult => {
                  setOrganization(organizationResult)
                  setUser({ ...firebaseUser, role: userResult.role, organizationId: userResult.organizationId, number: organizationResult.number })
                  setLoaded(true)
                })
              })
            })
        }

        refreshToken()
        const intervalId = setInterval(refreshToken, 10 * 60 * 1000)

        return () => {
          unsubscribe()
          clearInterval(intervalId)
        };
      } else {
        setUser(null)
        console.log("no user")
        setLoaded(true)
      }
    })

    return () => {
      unsubscribe()
    }
  }, [])


  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth={false} className="App">
        {loaded ?
          (user ?
            <Main2
              user={user}
              setUser={setUser}
              organization={organization}
              setOpenSnackBar={setOpenSnackBar}
              setSeveritySnackBar={setSeveritySnackBar}
              setMessageSnackBar={setMessageSnackBar}
            />
            : <Login
              setOpenSnackBar={setOpenSnackBar}
              setSeveritySnackBar={setSeveritySnackBar}
              setMessageSnackBar={setMessageSnackBar}
            />)
          : null}
        <SnackbarAlert
          open={openSnackBar}
          setOpen={setOpenSnackBar}
          severity={severitySnackBar}
          notificationMessage={messageSnackBar}
        />
      </Container>
    </ThemeProvider>
  );
}

export default App
