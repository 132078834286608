import { DataGrid } from '@mui/x-data-grid';
import LinearProgress from '@mui/material/LinearProgress';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Box, IconButton, Tooltip } from '@mui/material';

export default function UsersDataGrid({
    users,
    loading,
    handleOpen
}) {

    const columns = [
        { field: 'id', flex: 1 },
        { field: 'name', flex: 2 },
        { field: 'email', flex: 2 },
        { field: 'role', flex: 1 },
        { field: 'created', flex: 2 },
        { field: 'updated', flex: 2 },
        { field: 'active', flex: 1 },
        {
            field: 'actions', flex: 1,
            renderCell: (params) => (
                <Box
                    sx={{ display: 'flex', flexDirection: 'row', gap: '8px' }}
                >
                    <Tooltip title={"Edit user"}>
                        <IconButton
                            color='primary'
                            onClick={() => handleOpen('edit', params.value)}
                            disabled={users[params.value].role === "super-admin" ? true : false}
                        >
                            < EditIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={"Delete user"}>
                        <IconButton
                            color='primary'
                            onClick={() => handleOpen('delete', params.value)}
                            disabled={users[params.value].role === "super-admin" ? true : false}
                        >
                            < DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
            ),
        }
    ];

    return (
        <DataGrid
            slots={{
                loadingOverlay: LinearProgress,
            }}
            loading={loading}
            columns={columns}
            rows={users}
            disableRowSelectionOnClick
            sx={{maxWidth: '90vw', m: 2}}
        />
    );
}